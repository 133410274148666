import { TokenProvider } from "./TokenProvider";
import { createAxiosClient } from "./rest/AxiosClient";
import { TP_REDEMPTION_RATE } from "../@types/Constants";
import { store } from "../redux/store";

export const getMemberPoints = async (memberId: string): Promise<any> => {
  const token = await new TokenProvider().getAnonToken();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": `${process.env.GATSBY_CDP_SUBSCRIPTION_KEY}`,
      Authorization: `Bearer ${token}`,
      "Api-Version": `${process.env.GATSBY_CDP_API_VERSION}`,
    },
  };

  const axiosClient = await createAxiosClient({
    apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
  });

  try {
    const response = await axiosClient.getData(
      `/redemption/member/${memberId}/points`,
      config
    );
    return response;
  } catch (error: any) {
    console.error("Unexpected Error:", error);
  }
};

export const checkUserRedemptionEligiblity = (
  userPoints: number,
  pointsRequiredPerDay: number,
  numOfNights: number
) => {
  if (userPoints && pointsRequiredPerDay && numOfNights) {
    if (userPoints >= pointsRequiredPerDay * numOfNights) {
      return true;
    }
  }
  return false;
};

export const checkRedemptionRateInCheckout = (checkout: any) => {
  if (checkout && checkout.Rooms) {
    if (Object.keys(checkout.Rooms).length === 1) {
      const roomDetails: any = Object.values(checkout.Rooms)[0];
      if (
        roomDetails &&
        roomDetails?.rate &&
        TP_REDEMPTION_RATE.includes(roomDetails?.rate?.rateCode)
      ) {
        return true;
      }
    }
  }
  return false;
};

interface Reservation {
  redemption: {
    redeemedPoints: number;
  };
  billing: {
    costEstimate: number | null;
  };
  confirmNumber: string;
}
interface Trip {
  itineraryId: string;
  reservations: Reservation[];
}

export const checkUsedRedemptionPointsforReservation = (
  reservationId: string,
  upcomingStays: any
) => {
  const currentStoreState = store.getState();
  const userTrips =
    currentStoreState &&
    currentStoreState?.member &&
    currentStoreState?.member?.crmProfile &&
    currentStoreState?.member?.crmProfile?.trips
      ? currentStoreState?.member?.crmProfile?.trips
      : [];
  const reservation = userTrips.find((trip: Trip) =>
    trip.reservations.some(
      (reservation: Reservation) => reservation.confirmNumber === reservationId
    )
  );
  if (reservation) {
    const matchingReservation = reservation.reservations.find(
      (reservation: Reservation) => reservation.confirmNumber === reservationId
    );

    if (matchingReservation) {
      return matchingReservation.redemption.redeemedPoints;
    }
  }
  return 0;
  // Commenting old code for testing new code
  // if (
  //   reservationId &&
  //   Object.keys(upcomingStays).length > 0 &&
  //   upcomingStays[reservationId]
  // ) {
  //   return upcomingStays[reservationId].redeemedPoints;
  // }
  // return 0;
};

export const filterRedemptionRatesFromRooms = async (hotelData: any) => {
  // Removing TPRNR rate from Availability API response.
  try {
    const updatedRooms = await Promise.all(
      hotelData.rooms.map(async (room: any) => {
        const updatedRoomRates = room.roomRates.filter(
          (rate: any) =>
            rate.__typename !== "QueryRoomRateInfo" ||
            TP_REDEMPTION_RATE.includes(rate.rateCode)
        );
        return {
          ...room,
          roomRates: updatedRoomRates,
        };
      })
    );

    const updatedHotelData = {
      ...hotelData,
      rooms: updatedRooms,
    };

    return updatedHotelData;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const checkEditFlowByPathname = (pathname: string) => {
  const editFlowPathnames = [
    "/edit-reservation/select-room",
    "/edit-reservation/select-rate",
    "edit-reservation/select-payment",
  ];
  return pathname && editFlowPathnames.includes(pathname);
};
