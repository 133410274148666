import axios, { AxiosInstance } from "axios";

export interface IAxiosClientProps {
    apiEndpoint?: string;
    token?: string;
}
export class AxiosBaseClient { 
    protected client: AxiosInstance;

    constructor(params: IAxiosClientProps) {
        let headers = {};
        if (params.token) {
            headers = {...headers, authorization: `Bearer ${params.token}`}
        }
        this.client = axios.create({
            baseURL: params.apiEndpoint,
            headers: headers
        });
    }
}