import React,{ createContext, useState }from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { StaticQuery, graphql } from "gatsby";
import { store } from "./redux/store";
import { hydrateBrandState } from "./redux/slices/Brand/brand";
// import { ServiceContextProvider } from "./services/serviceContextProvider"; -- dev Comments
//import 'bootstrap/dist/css/bootstrap.min.css';
// const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const persistor = persistStore(store);

const wrapper = ({ element }) => {
  const dispatch = store.dispatch;

  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    let loc = document.location;
    window.dataLayer.push({
      originalLocation: loc.href,
    });
  }

  return (
    <StaticQuery
      query={graphql`
        query BrandData {
          allBrand(
            filter: {relationships: {}, field_sites: {elemMatch: {drupal_internal__target_id: {eq: 31}}}}
          ) {
            edges {
              node {
                id
                name
                brand_id: drupal_internal__id
                brand_code
                field_color {
                  color
                }
                path {
                  alias
                }
                relationships {
                  field_logo {
                    publicUrl
                    gatsbyImage(
                      width: 200
                      height: 30
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                  field_logo_alternate {
                    uri {
                      url
                    }
                  }
                  paragraphs: field_sections {
                    type: __typename
                    ...ParagraphPageBanner
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let _brands = [];
        data.allBrand.edges.forEach((x) => {
          _brands.push(x.node);
        });
        dispatch(hydrateBrandState(_brands));

        if (typeof window === "undefined") {
          return <Provider store={store}>{element}</Provider>;
        }

        return (
          // <ServiceContextProvider> - dev comments - will explore
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {element}
              </PersistGate>
            </Provider>
          // </ServiceContextProvider>
        );
      }}
    />
  );
};
export default wrapper;
