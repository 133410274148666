import moment from "moment-mini";
import { DateUtils } from "react-day-picker";

export const searchDateFormat = "YYYY-MM-DD";
export const parseDateFormat = "M/dd/yyyy h:mm:ss a";
export const displayDateFormat = "MMM d, yy";

export const resDisplayDateFormat = "MMM DD, YYYY";
export const resParseDateFormat = "MM/DD/YYYY h:mm:ss a";

export const parseDate = (
  str: string,
  dteFormat: string /* , locale: string = 'en' */
): Date | undefined => {
  const parsed = moment(str, dteFormat).toDate(); //parse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

export const parseSearchDate = (
  str: string /* , locale: string = 'en' */
): Date | 0 => {
  const parsed = moment(str, searchDateFormat).toDate(); //parse(str, searchDateFormat, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return 0;
};

export const formatDate = (
  date: Date | undefined | 0,
  dteFormat: string /* , locale?: string */
): string => {
  return moment(date).format(dteFormat); //format(date, dteFormat, { locale });
};

/**
 * Custom format the date string to a specific format.
 * @param {string} date - The date string to format.
 * @returns {string|undefined} The formatted date string or undefined if the input is invalid.
 */
export const customFormatDate = (date: string): string | undefined => {
  // check if the date is already in yyyy-mm-dd
  if (moment(date, "YYYY-MM-DD", true).isValid()) {
    return date;
  }
  // Split the date string by "-" to separate the date and time parts
  const splitDate = date?.split("-");

  // If the splitDate array exists and has at least 3 elements
  if (splitDate?.length >= 3) {
    // Split the time part by "T" to separate the day from the time
    const splitTime = splitDate[2]?.split("T");

    // If the splitTime array exists and has at least 2 elements
    if (splitTime?.length >= 2) {
      // Create the result date string in the desired format: "YYYY-MM-DD"
      const resultDate = `${splitDate[0]}-${splitDate[1]}-${splitTime[0]}`;

      // If the resultDate is not empty
      if (resultDate) {
        return resultDate;
      }
    }
  }

  // If the input date is invalid or the formatting failed, return undefined
  return undefined;
};

/**
 * Custom format the date string to a GAPI specific format.
 * @param {string} date - The date string to format - 2023-08-06.
 * @returns {string|undefined} The formatted date string or undefined if the input is invalid - 2023-08-06T00:00:00.000Z || undefined.
 */
export const formatDateGAPI = (date: string): string | undefined => {
  const dateFormat = "YYYY-MM-DD";
  const dateConcatString = "T00:00:00.000Z";

  if (date) {
    const formatDate = `${moment(date).format(dateFormat)}${dateConcatString}`;

    return formatDate;
  }

  // If the input date is invalid or the formatting failed, return undefined
  return undefined;
};
