import * as Sentry from "@sentry/gatsby";
import { CaptureConsole, HttpClient } from "@sentry/integrations";

// if (
//   process?.env?.NODE_ENV !== "development" &&
//   process?.env?.GATSBY_SENTRY_DSN
// ) {
Sentry.init({
  // dsn: process.env.GATSBY_SENTRY_DSN,
  dsn: process.env.GATSBY_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
    new HttpClient(),
    new CaptureConsole({
      levels: ["log", "info", "warn", "error", "debug", "assert"],
    }),
  ],
  debug: true,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// }
