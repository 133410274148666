import { AxiosRequestConfig } from "axios";
import { AxiosBaseClient, IAxiosClientProps } from "./AxiosBaseClient";

export class AxiosClient extends AxiosBaseClient {
  constructor(params: IAxiosClientProps) {
    super(params);
  }
  public async getData(endpoint: string, config?: AxiosRequestConfig) {
    try {
      const response = await this.client.get(endpoint, config);
      return this.processResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  public async postData(
    endpoint: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    try {
      const response: any = await this.client.post(endpoint, data, config);
      return this.processResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  public async putData(
    endpoint: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    try {
      const response: any = await this.client.put(endpoint, data, config);
      return this.processResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  public async deleteData(endpoint: string, config?: AxiosRequestConfig) {
    try {
      const response: any = await this.client.delete(endpoint, config);
      return this.processResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  private processResponse(response: any) {
    if (response.error) {
      console.error(response.error.message);
      return response.error;
    } else {
      return response?.data;
    }
  }
}
export const createAxiosClient = (params: IAxiosClientProps) => {
  return new AxiosClient(params);
};
