import { gql } from "@apollo/client";
import { ApolloBaseClient, IApolloClientProps } from "./ApolloBaseClient";
import { createApolloClient } from "./ApolloClient";
import { TokenProvider } from "../TokenProvider";
import { ITokenProvider } from "../@types/MemberTypes";
import { IUpdateUserProfileRequestInput } from "../@types/RequestTypes";
import { ICreateProfileResponse } from "../@types/ResponseTypes";
import { GET_BASIC_PROFILE_QUERY } from "./MemberQuery";

export class MemberClient extends ApolloBaseClient {
  protected client: any;
  public token: any;
  protected tokenProvider: ITokenProvider;

  constructor(params: IApolloClientProps) {
    super(params);

    this.client = createApolloClient(params);
    this.tokenProvider = new TokenProvider();
  }
  async getMemberProfile() {
    const token = await this.tokenProvider.getUserToken();
    const result = await this.client.getData(
      {
        query: GET_BASIC_PROFILE_QUERY,
        variables: {},
      },
      token,
      true,
      null,
      true
    );

    return result;
  }
  async editUserProfile(
    requestInput: IUpdateUserProfileRequestInput
  ): Promise<ICreateProfileResponse> {
    const mutation = gql`
      mutation EditUserProfile(
        $updateUserProfileRequest: UpdateUserProfileRequestInput!
      ) {
        editUserProfile(updateUserProfileRequest: $updateUserProfileRequest) {
          message
          statusCode
        }
      }
    `;
    const token = await this.tokenProvider.getUserToken();
    const result = await this.client.postData(
      {
        mutation,
        variables: { updateUserProfileRequest: requestInput },
      },
      token,
      true
    );

    return result;
  }
}
export const createMemberClient = async (params: IApolloClientProps) => {
  const client = new MemberClient(params);
  client.token = await new TokenProvider().getUserToken();
  return client;
};
