import {
  createSlice,
  PayloadAction,
  createSelector,
  OutputSelector,
} from "@reduxjs/toolkit";
import addMinutes from "date-fns/addMinutes";

import { AppDispatch, AppThunk } from "../../store";
import { getCRSRates, getCRSRatesASE } from "../../../services/rates";
import { Storage } from "../../../utils/storage";
import { setSearch } from "../Search/search";
import { CancelTokenSource } from "axios";
import { addHotelAmenities } from "../Hotel/hotel";

const _initialState = {};

const rateSlice = createSlice({
  name: "rate",
  initialState: _initialState,
  reducers: {
    saveRates: (state: any, { payload }: PayloadAction<any>) => {
      state[payload.crsCode] = payload;
      return state;
    },
    clearRates: (state: any, { payload }: PayloadAction<any>) => {
      return payload;
    },
  },
});

const specialPriceSlice = createSlice({
  name: "specialPrice",
  initialState: false,
  reducers: {
    unlockPricing: (state: any, { payload }: PayloadAction<any>) => {
      Storage.SetLocalStorageValue("HR-discount-unlocked", true);
      return { ...state, payload };
    },
  },
});

const ratePlanFilterSlice = createSlice({
  name: "ratePlanFilter",
  initialState: "",
  reducers: {
    saveRatePlanFilter: (_state: any, { payload }: PayloadAction<any>) => {
      return payload;
    },
  },
});

export const ratesReducer = rateSlice.reducer;
export const { saveRates, clearRates } = rateSlice.actions;

export const specialPricingReducer = specialPriceSlice.reducer;
export const { unlockPricing } = specialPriceSlice.actions;

export const ratePlanFilterReducer = ratePlanFilterSlice.reducer;
export const { saveRatePlanFilter } = ratePlanFilterSlice.actions;

//Async actions
export const addRates =
  (
    crsCode: string,
    apiUrl?: any | null,
    source?: CancelTokenSource
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await getCRSRates(apiUrl, source);
    if (response) {
      const data = Object.values(response);
      const rates = data[0];
      dispatch(
        rateSlice.actions.saveRates({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...rates,
          crsCode: crsCode,
          expireCache: rates !== null ? addMinutes(new Date(), 5) : null,
          searchQuery: rates !== null ? apiUrl : null,
        })
      );
    }
  };

export const addRatesASE =
  (
    apiUrl: string,
    adults: number,
    children: number,
    chidlrenAges: string,
    crsHotelCode: string,
    chainId: string,
    start: string,
    end: string,
    availReqType: string,
    promotionCode: string,
    ratePlanCode: string,
    ratePlanFilterCode: string,
    abortController: any
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await getCRSRatesASE(
      adults,
      children,
      chidlrenAges,
      crsHotelCode,
      chainId,
      start,
      end,
      availReqType,
      promotionCode,
      ratePlanCode,
      ratePlanFilterCode,
      abortController
    );
    // ASE - review
    // if (response && response.Warnings) {
    //   const message =
    //     (response.Warnings[0] && response.Warnings[0].message) || "";
    //   if (message.indexOf("Invalid Rate Filter Code") != -1) {
    //     dispatch(setSearch({ ratePlanFilterCode: "" }));
    //   }
    // }
    if (response) {
      const rates = {
        Name: response.name,
        Rooms: response.rooms,
        redemptionItem: response.redemptionItem,
        hotelLocation: response?.hotelLocation,
        Brand: response?.brand.code,
      };
      dispatch(
        rateSlice.actions.saveRates({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...rates,
          crsCode: crsHotelCode,
          expireCache: rates !== null ? addMinutes(new Date(), 5) : null,
          searchQuery: rates !== null ? apiUrl : null,
        })
      );
      dispatch(addHotelAmenities(response));
    }
  };

//Selectors
const selectAllRates = (state: { rates: any }): any => state.rates;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const selectHotelRates = (
  codes: any[]
): OutputSelector<
  {
    rates: any;
  },
  any,
  (res: {
    [x: string]: any;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }) => any
> =>
  createSelector(selectAllRates, (rates: { [x: string]: any }) => {
    const filtered = codes.reduce(
      (obj: any, key: string | number) => ({
        ...obj,
        [key]: rates && rates[key],
      }),
      {}
    );
    return filtered;
  });
