import Axios from "axios";
import { IGuestWareProfile } from "../@types/IGuestWareProfile";
import { IEnrollMember } from "../@types/IEnrollMember";
import { IUpdateMemberProfile } from "../@types/IUpdateMemberProfile";
import {
  getProfileFromGuestware,
  getProfileFromCDP,
  signUp,
} from "../utils/auth0Client";
import { guestServiceProvider } from "./ServiceProvider";

export const enrollMember = async ({
  email,
  firstname,
  lastname,
  // source,
  synxisHotelId,
  hotelName,
}: IEnrollMember) => {
  try {
    return signUp(email, firstname, lastname, {
      wifi: !!synxisHotelId,
      hotel_name: hotelName,
      hotel_id: synxisHotelId,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return "Oops! something went wrong.";
  }

  // TODO: The signUp API (see Postman) call creates unactivated user in Auth0,
  // Auth0 then sends email to activate. Auth0 also pings
  // Guestware and creates user (I need to figure out how!)
  // All of this implies we may NOT need to invoke api/enroll* directly.
  // However, I noticed that the user in Guestware does NOT contain any info
  // other than the email address when I invoke the api/profile endpoint.

  // const url = synxisHotelId
  //   ? `/api/enrollWifi`
  //   : `/api/enrollRegular`;

  // try {
  //   const response = await fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(data)
  //   });
  //   const result = await response.json();
  //   return result;
  // } catch (err) {
  //   console.error(err);
  //   return null;
  // }
};
export const getMemberProfile = async (
  idToken: string,
  baseProfile: boolean
): Promise<IGuestWareProfile | string | null> => {
  try {
    const profileWrapper = await getProfileFromGuestware(idToken, baseProfile);
    return profileWrapper.data;
  } catch (err: any) {
    if (
      err.message &&
      (err.message.indexOf("403") != -1 ||
        err.message.indexOf("forbidden") != -1)
    ) {
      return "forbidden";
    }
    return null;
  }
};

export const getMemberProfileCDP = async (
  idToken: string,
  baseProfile: boolean
): Promise<IGuestWareProfile | string | null> => {
  try {
    const profileWrapper = await getProfileFromCDP(idToken, baseProfile);
    return profileWrapper.data;
  } catch (err: any) {
    if (
      err.message &&
      (err.message.indexOf("403") != -1 ||
        err.message.indexOf("forbidden") != -1)
    ) {
      return "forbidden";
    }
    return null;
  }
};

export const updateMemberProfileCDP = async (
  idToken: string,
  data: IUpdateMemberProfile
): Promise<boolean> => {
  const url = "/api/profileCDP";

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.status === 200;
};

export const updateMemberProfile = async (
  idToken: string,
  data: IUpdateMemberProfile
): Promise<boolean> => {
  const url = "/api/profile";

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.status === 200;
};

type CompleteSignUpProcessParams = {
  newPassword: string;
  address?: {
    countryCode?: string;
    postalCode?: string;
    stateCode?: string;
    city?: string;
    addressLine1?: string;
    addressLine2?: string;
  };
  phones?: {
    mobile?: string;
  };
  args?: string;
};

export const completeSignUpProcess = async (
  params: CompleteSignUpProcessParams
) => {
  const result = await Axios.post(
    `${window.location.origin}/api/signUpChangePasswordCDP`,
    params,
    {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    }
  );
  return result.data;
};

export const checkGuestUserExist = async (email: string): Promise<void> => {
  // let url;
  // if(process.env.GATSBY_IS_CDP_ENABLED !== "ENABLE"){
  //   url = "/api/checkUserFromGuestWare";
  // }else{
  //   url = "/api/checkUserFromCDP";
  // }
  // const payload = {
  //   email: email,
  // };
  // const response = await fetch(url, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(payload),
  // });
  // const result = await response.json();
  // result.status = response.status;
  // return result;
  try {
    const guestService = await guestServiceProvider();
    const response = await guestService.checkUserByEmail(email);
    return response.userByEmail;
  } catch (error) {
    console.error(error);
  }
  

  
};
