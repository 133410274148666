import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk, RootState } from "../../store";
import { fetchHotels } from "../../../services/hotels";

const _initialState = {};

const redlionhotelSlice = createSlice({
  name: "redlionhotel",
  initialState: _initialState,
  reducers: {
    redlionHotels: (_state: any, { payload }: PayloadAction<any>) => {
      return payload;
    },
  },
});

export const redlionhotelReducer = redlionhotelSlice.reducer;
export const { redlionHotels } = redlionhotelSlice.actions;

//Async actions
export const loadRedlionHotels =
  (
    distance?: number,
    latitude?: number | null,
    longitude?: number | null,
    siteId?: number,
    source?: any | null
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const hotels = await fetchHotels(
      distance,
      latitude,
      longitude,
      siteId,
      source
    );
    dispatch(redlionhotelSlice.actions.redlionHotels(hotels));
  };

//Selectors
export const redlionhotelsSelector = (state: RootState): any =>
  state.redlionHotels;
