import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import { getCRSSubSource } from "../utils/helpers";
import { coreGet, coreRequest, coreGetContent } from "./core";
import { guestServiceProvider } from "./ServiceProvider";
import { AxiosClient, createAxiosClient } from "./rest/AxiosClient";
import { IAxiosClientProps } from "./rest/AxiosBaseClient";
import { formatDateGAPI } from "./dates";
import { TokenProvider } from "./TokenProvider";
import { TP_REDEMPTION_RATE } from "../@types/Constants";
import { store } from "../redux/store";

export const fetchReservationDetails = async (
  params: {
    resId: string;
    lastName: string;
    hotelCode?: string;
  },
  source = null
): Promise<
  | {
      error: boolean;
      errorSummary: any;
      value?: undefined;
    }
  | {
      error: boolean;
      value: any;
      errorSummary?: undefined;
    }
> => {
  const resId = params.resId.toUpperCase();
  const lastName = params.lastName.trim().toLowerCase();
  const crs = getCrsFromId(/* resId */);
  // const hotelCode = !params.hotelCode ? getHotelCodeFromId(resId, crs) : params.hotelCode;
  // const siteId = process.env.SITE_ID || 31;
  // const queryString = `ID=${resId}&HotelCode=${hotelCode}&Surname=${lastName}&siteId=${siteId}`;
  // const apiUrl = encodeURI(`/${crs}/reservation?${queryString}`);
  let returnData;
  try {
    // const response = await coreGet(apiUrl, source);
    const guestService = await guestServiceProvider();
    const response = await guestService.getReservationDetails(resId, lastName);
    if (response.reservation.statusCode === 404) {
      returnData = {
        error: true,
        errorSummary:
          "You entered an invalid reservation ID or lastname. Please try again",
      };
    } else {
      returnData = {
        error: false,
        value: { ...response.reservation, Crs: crs },
      };
    }
  } catch (error) {
    returnData = {
      error: true,
      errorSummary: "Oops!! Something went wrong, please try again.",
    };
  }
  return returnData;
};

export const fetchHotelDetails = async (
  params: {
    crs: string;
    hotelCode?: string;
    resId: string;
  },
  source = null
): Promise<
  | {
      error: boolean;
      errorSummary: any;
      value?: undefined;
    }
  | {
      error: boolean;
      value: any;
      errorSummary?: undefined;
    }
> => {
  const crs = params.crs || getCrsFromId(/* params.resId */);
  const hotelCode = !params.hotelCode
    ? getHotelCodeFromId(params.resId, crs)
    : params.hotelCode;

  const siteId = process.env.SITE_ID || 31;
  const queryString = `HotelCode=${hotelCode}&siteId=${siteId}`;
  const apiUrl = encodeURI(`/${crs}/details?${queryString}`);
  let returnData;
  try {
    const response = await coreGet(apiUrl, source);
    if (response.error) {
      returnData = {
        error: true,
        errorSummary: response.message,
      };
    } else {
      returnData = {
        error: false,
        value: response,
      };
    }
  } catch (error) {
    returnData = {
      error: true,
      errorSummary: "Oops!! Something went wrong, please try again.",
    };
  }
  return returnData;
};

export const fetchHotelDetailsFromCore = async (
  crsCode: string,
  source?: any | undefined
): Promise<any> => {
  const apiUrl = `/hotels?filter[status][value]=1&filter[crs_code][value]=${crsCode}&include=brand_id`;
  const data = await coreGetContent(apiUrl, source);

  return data;
};

export const createReservation = async (
  data?: any | undefined,
  source = null
): Promise<any> => {
  // let response = null;
  // let config = {
  //   headers: {
  //     'api-key': process.env.GATSBY_CORE_API_KEY
  //   }
  // };
  // if (source !== null) {
  //   config.cancelToken = source.token;
  // }
  // try {
  //   const apiResponse = await Axios.post(
  //     'https://rlhcorestg.prod.acquia-sites.com/api/billing?_format=json',
  //     data,
  //     config
  //   );
  //   if (apiResponse.status === 200) {
  //     response = await apiResponse.data;
  //   }
  // } catch (error) {
  //   console.log(error)
  // }

  const apiUrl = `/billing`;
  const siteId = process.env.SITE_ID || 31;
  const subSource = getCRSSubSource();
  const response = await coreRequest({
    url: apiUrl,
    data: {
      ...data,
      siteId: siteId,
      ...(subSource && { subSource: subSource }),
    },
    source: source,
  });

  return response;
};

export const updateReservation = async (
  data?: any | undefined,
  source = null
): Promise<any> => {
  // let response = null;
  // let config = {
  //   headers: {
  //     'api-key': process.env.GATSBY_CORE_API_KEY
  //   }
  // };
  // if (source !== null) {
  //   config.cancelToken = source.token;
  // }
  // try {
  //   const apiResponse = await Axios.put(
  //     'https://rlhcorestg.prod.acquia-sites.com/api/billing?_format=json',
  //     data,
  //     config
  //   );
  //   if (apiResponse.status === 200) {
  //     response = await apiResponse.data;
  //   }
  // } catch (error) {
  //   console.log(error)
  // }

  const apiUrl = `/billing`;
  const siteId = process.env.SITE_ID || 31;
  const subSource = getCRSSubSource();
  const response = await coreRequest({
    url: apiUrl,
    data: {
      ...data,
      siteId: siteId,
      ...(subSource && { subSource: subSource }),
    },
    source: source,
    method: "put",
  });
  return response;
};

export const cancelReservationAPI = async (
  params: {
    hotelCode?: string;
    resId: string;
  },
  source = null
): Promise<{
  cancelID: any;
  refundResponse: any;
} | null> => {
  const crs = getCrsFromId(/* params.resId */);
  const hotelCode = !params.hotelCode
    ? getHotelCodeFromId(params.resId, crs)
    : params.hotelCode;

  const siteId = process.env.SITE_ID || 31;
  const subSource = getCRSSubSource();

  const queryData = {
    ID: params.resId,
    HotelCode: hotelCode,
    siteId: siteId,
    ...(subSource && { subSource: subSource }),
    EmailTemplate: "Guestconf",
  };

  const apiUrl = `/${crs}/reservation?_format=json`;
  try {
    const response = await coreRequest({
      url: apiUrl,
      data: queryData,
      method: "patch",
      source: source,
    });

    let refundResponse = null;
    if (response.ID) {
      refundResponse = await refundOrder(params.resId, source);
    }
    return {
      cancelID: response.ID,
      refundResponse: refundResponse,
    };
  } catch (e) {
    return null;
  }
};

export const refundOrder = async (
  resId?: any | undefined,
  source = null
): Promise<any> => {
  const siteId = process.env.SITE_ID || 31;
  const queryData = {
    reservationId: resId,
    siteId: siteId,
  };
  const apiUrl = `/hello-rewards/refundorder`;

  const response = await coreRequest({
    url: apiUrl,
    data: queryData,
    source: source,
  });
  return response;
};

function getHotelCodeFromId(id: any, crs: string): string | undefined {
  if (crs === "synxis") {
    const charArray = [];
    for (let i = 0; i < id.length; i++) {
      if (isNaN(parseInt(id.charAt(i), 10))) {
        break;
      }
      charArray.push(id.charAt(i));
    }
    return charArray.join("");
  }
}

function getCrsFromId(/* id: any */): string {
  return "synxis";
}

interface ICheckout {
  GuestDetails: any;
  Rooms: Array<IRoom>;
  Payment: {
    with_giftcards: any;
    giftcard_amount: string | number;
  };
  Start: Date | string;
  End: Date | string;
  Crs: string;
  groupCode: string;
  HotelCode: string;
  promotionCode: string;
  SpecialRequests: any;
  isResubmitted?: boolean;
}

interface IRoom {
  childrenAges: Array<number>;
  rate: {
    RoomRateNightly: Array<IRoomRate>;
    Subtotal: string;
    RateCode: string;
    CurrencyCode: string;
  };
  services: Array<IRoomService>;
  adults: number;
  children: number;
  room: {
    RoomCode: string;
  };
}

interface IRoomRate {
  Rate: string;
  EffectiveDate: Date | string;
  ExpireDate: Date | string;
}

interface IRoomService {
  Description: string;
  AvailedQuantity: number;
  ServiceInventoryCode: string;
  ServiceRPH: string;
  Price: string;
  Tax: string;
}

export const createResRequestJSON = (checkout: ICheckout): any => {
  const guests = checkout.GuestDetails || {};
  const rooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
  const roomsForRequest: Array<any> = [];
  const payment = checkout.Payment;
  const applyBucks = !!payment.with_giftcards;
  const isResubmitted = checkout?.isResubmitted || false;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let bucksAmount = parseFloat(payment.giftcard_amount);
  let appliedBucks = 0;
  const currency = rooms && rooms.length ? rooms[0].rate?.currencyCode : "USD";
  rooms.forEach((room: IRoom) => {
    // create room rate request object
    const roomRates = []; //@TODO : consider gift card amount
    if (
      room.rate &&
      room.rate.roomRateNightly &&
      room.rate.roomRateNightly.length > 1
    ) {
      //multi nights
      room.rate.roomRateNightly.forEach((rate: IRoomRate) => {
        let amountBeforeTax = parseFloat(rate.rate);
        if (applyBucks && bucksAmount > 0 && amountBeforeTax > 0) {
          if (bucksAmount <= amountBeforeTax) {
            amountBeforeTax = amountBeforeTax - bucksAmount;
            appliedBucks += bucksAmount;
            bucksAmount = 0;
          } else {
            bucksAmount = bucksAmount - amountBeforeTax;
            appliedBucks += amountBeforeTax;
            amountBeforeTax = 0;
          }
        }
        roomRates.push({
          AmountBeforeTax: amountBeforeTax,
          EffectiveDate: rate.effectiveDate.substring(0, 10),
          ExpireDate: rate.expireDate.substring(0, 10),
        });
      });
    } else {
      // add rates for single night.
      let amountBeforeTax = parseFloat(room.rate.subTotal);

      if (applyBucks && bucksAmount > 0 && amountBeforeTax > 0) {
        if (bucksAmount <= amountBeforeTax) {
          amountBeforeTax = amountBeforeTax - bucksAmount;
          appliedBucks += bucksAmount;
          bucksAmount = 0;
        } else {
          bucksAmount = bucksAmount - amountBeforeTax;
          appliedBucks += amountBeforeTax;
          amountBeforeTax = 0;
        }
      }

      roomRates.push({
        AmountBeforeTax: amountBeforeTax,
        EffectiveDate: checkout.Start,
        ExpireDate: checkout.End,
      });
    }

    // create room services object
    const roomServices: Array<any> = [];
    room.services &&
      Object.values(room.services).forEach((ser: IRoomService) => {
        roomServices.push({
          Description: ser.Description,
          Quantity: ser.AvailedQuantity,
          ServiceInventoryCode: ser.ServiceInventoryCode,
          ServiceRPH: ser.ServiceRPH,
          Total:
            (parseFloat(ser.Price) + parseFloat(ser.Tax)) * ser.AvailedQuantity,
        });
      });

    const roomObj = {
      Count: {
        Adult: room.adults,
        Child: {
          numGuests: room.children,
          ages: room.childrenAges,
        }, //room.children || 0
      },
      RoomTypeCode: room.room.RoomCode,
      RatePlanCode: room.rate.RateCode,
      RoomRates: roomRates,
      roomServices: roomServices,
    };
    //@TODO Need to specifically test for groupCode
    // if(checkout.Crs == 'windsurfer' && checkout.groupCode) {
    //   roomObj.RatePlanCode = `G:${checkout.groupCode}`; // Not sure if room.rate.RateCode and checkout.groupCode would be same
    // }
    roomsForRequest.push(roomObj);
  });
  if (applyBucks) {
    payment.giftcard_amount = Math.ceil(appliedBucks);
  }
  let loyalLevel = null;
  if (guests.profileType) {
    switch (guests.profileType) {
      case "Member":
        loyalLevel = "1";
        break;
      case "Preferred":
        loyalLevel = "2";
        break;
      case "Elite":
        loyalLevel = "3";
        break;
      default:
        loyalLevel = "1";
    }
  }

  const request: any = {
    payment: payment,
    reservation: {
      Rooms: roomsForRequest,
      Start: checkout.Start,
      End: checkout.End,
      crs: checkout.Crs,
      HotelCode: checkout.HotelCode,
      NumberOfUnits: 1,
      ResGuests: [
        {
          Email: guests.email,
          GivenName: guests.firstName,
          Surname: guests.lastName,
          PhoneNumber: guests.phoneNumber,
          MembershipID: guests.id,
        },
      ],
      PromotionCode: checkout.promotionCode,
      EmailTemplate: "Guestconf",
      MembershipID: guests.id,
      ProgramID: "Travel Pass",
      LoyalLevel: loyalLevel,
      CurrencyCode: currency,
      isResubmitted: isResubmitted,
    },
  };
  if (checkout.groupCode) {
    //TODO:
    //request.reservation.InvBlockCode = checkout.groupCode;
    // request.reservation.RatePlanCode = `G:${checkout.groupCode}`;
  }
  if (checkout.SpecialRequests) {
    request.reservation.SpecialRequests = checkout.SpecialRequests;
  }

  return request;
};

//CBF
interface Guest {
  membershipId?: string;
  id: any;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  isPrimary: boolean;
}

interface Child {
  numGuests: number;
  ages: number[];
}

interface Room {
  rate: any;
  room: any;
  childrenAges: never[];
  children: any;
  adults: any;
  count: {
    adult: number;
    child: Child;
  };
  roomTypeCode: string;
  ratePlanCode: string;
  roomRates: {
    expireDate: string;
    effectiveDate: string;
    amountBeforeTax: number;
  }[];
}

interface InitiatePayload {
  hotelId: string;
  startDate: string;
  endDate: string;
  numberOfUnits: number;
  guestList: Guest[];
  roomsList: Room[];
  emailTemplate: string;
  groupCode?: string;
  promotionCode?: string;
  subSource: string;
  programId: string;
  loyalLevel: string;
  guestId?: string;
}

export function createInitiateReservationPayload(
  roomIds: string[],
  updatedCheckout: {
    HotelCode: string;
    Start: string;
    End: string;
    GuestDetails: Guest;
    groupCode?: string;
    promotionCode?: string;
    Rooms: Record<string, Room>;
    Brand?: string | null;
    hotelLocation?: string | null;
    redemptionItem?: any;
  },
  profileType = "Bronze"
): InitiatePayload {
  const memberState = store.getState().member;
  let isRedemptionRate;
  const roomsList = roomIds.map((roomId) => {
    const room = updatedCheckout.Rooms[roomId];
    if (TP_REDEMPTION_RATE.includes(room.rate.RateCode)) {
      isRedemptionRate = true;
    }
    return {
      count: {
        adult: room.adults,
        child: {
          numGuests: room.children,
          ages: room.childrenAges
            ? room.childrenAges.map((age) => {
                if (age === "") {
                  return 0; // Convert empty string to 0
                } else {
                  return Number(age); // Convert string to number
                }
              })
            : [],
        },
      },
      roomId: roomId,
      roomTypeCode: room.room.RoomCode,
      ratePlanCode: room.rate.RateCode,
      roomRates: room.rate.roomRateNightly.map(
        (nightlyInfo: { expireDate: any; effectiveDate: any; rate: any }) => {
          return {
            expireDate: nightlyInfo.expireDate,
            effectiveDate: nightlyInfo.effectiveDate,
            amountBeforeTax: nightlyInfo.rate,
          };
        }
      ),
    };
  });
  const guestId = updatedCheckout?.GuestDetails?.id
    ? updatedCheckout?.GuestDetails?.id
    : memberState.isLoggedIn && isRedemptionRate
    ? memberState.crmProfile?.memberId
    : "";
  const subSource = getCRSSubSource();
  const rooms = updatedCheckout.Rooms
    ? Object.values(updatedCheckout.Rooms)
    : [];
  const currency = rooms && rooms.length ? rooms[0].rate?.currencyCode : "USD";
  const initiatePayload: InitiatePayload = {
    hotelId: updatedCheckout.HotelCode,
    startDate: updatedCheckout.Start
      ? formatDateGAPI(updatedCheckout.Start)
      : "",
    endDate: updatedCheckout.End ? formatDateGAPI(updatedCheckout.End) : "",
    numberOfUnits: 1,
    guestList: [
      {
        firstName: updatedCheckout.GuestDetails.firstName,
        lastName: updatedCheckout.GuestDetails.lastName,
        email: updatedCheckout.GuestDetails.email,
        membershipId: guestId,
        phoneNumber: updatedCheckout.GuestDetails.phoneNumber || "",
        isPrimary: true,
      },
    ],
    roomsList,
    emailTemplate: isRedemptionRate ? "TPRedeem" : "Guestconf",
    currencyCode: currency,
    groupCode: updatedCheckout.groupCode || "",
    promotionCode: updatedCheckout.promotionCode || "",
    ...(subSource && { subSource: subSource }),
    guestId: guestId,
    signupAsMember: updatedCheckout?.GuestDetails?.helloRewardsSignup
      ? updatedCheckout.GuestDetails.helloRewardsSignup
      : false,
    programId: "Travel Pass",
    loyalLevel: profileType,
    RedemptionItemCode:
      updatedCheckout?.redemptionItem?.redemptionItemCode || "",
    HotelLocation: updatedCheckout?.hotelLocation,
    Brand: updatedCheckout?.Brand,
  };

  return initiatePayload;
}

export async function initiateReservation(
  payload: InitiatePayload
): Promise<any> {
  try {
    //check the email is associated with member account
    const token = await new TokenProvider().getAnonToken();
    const config = await fetchReservationAPIConfig(token);
    const axiosClientProps: IAxiosClientProps = {
      apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
    };

    const reservationClient = new AxiosClient(axiosClientProps);

    return await reservationClient.postData(
      "/reservation/transaction/initiate",
      payload,
      config
    );
  } catch (error) {
    console.error("Unexpected Error:", error);
    throw error;
  }
}

const fetchReservationAPIConfig = async (token: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": `${process.env.GATSBY_CDP_SUBSCRIPTION_KEY}`,
      Authorization: `Bearer ${token}`,
      "Api-Version": `${process.env.GATSBY_CDP_API_VERSION}`,
    },
  };
  return config;
};

export const cancelInitiatedReservation = async (
  reservationTransactionId: string
): Promise<any> => {
  const token = await new TokenProvider().getAnonToken();
  const config = await fetchReservationAPIConfig(token);
  const axiosClient = await createAxiosClient({
    apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
  });
  const response = await axiosClient.deleteData(
    `/reservation/transaction/${reservationTransactionId}/cancel`,
    config
  );
  return response;
};

export const createReservationCBF = async (
  data?: any | undefined,
  reservationTransactionId: string,
  source = null
): Promise<any> => {
  const token = await new TokenProvider().getAnonToken();
  const config = await fetchReservationAPIConfig(token);
  const axiosClient = await createAxiosClient({
    apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
  });

  try {
    const response = await axiosClient.postData(
      `/reservation/transaction/${reservationTransactionId}/commit`,
      data,
      config
    );
    return response;
  } catch (error: any) {
    const statusCodeMatch = error.message.match(/(\d+)/);
    if (
      statusCodeMatch &&
      statusCodeMatch?.length &&
      statusCodeMatch[0] === "408"
    ) {
      // If COMMIT API timesout status Code 408, calling STATUS API
      const statusResponse = await axiosClient.getData(
        `/reservation/transaction/${reservationTransactionId}/status`,
        config
      );
      return statusResponse;
    }
    throw error;
  }
};

export const updateReservationCBF = async (
  data?: any | undefined,
  reservationId: string
): Promise<any> => {
  const token = await new TokenProvider().getAnonToken();
  const config = await fetchReservationAPIConfig(token);
  const axiosClient = await createAxiosClient({
    apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
  });
  try {
    const response = await axiosClient.putData(
      `/reservation/${reservationId}/transaction/modify`,
      data,
      config
    );
    return response;
  } catch (error: any) {
    console.error("Unexpected Error:", error);
  }

  // const apiUrl = `/billing`;
  // const siteId = process.env.SITE_ID || 31;
  // const subSource = getCRSSubSource();
  // const response = await coreRequest({
  //   url: apiUrl,
  //   data: {
  //     ...data,
  //     siteId: siteId,
  //     ...(subSource && { subSource: subSource }),
  //   },
  //   source: source,
  //   method: "put",
  // });
};

export const createEditReservationPayload = (
  checkout: ICheckout,
  ccDetails: any
): any => {
  let isRedemptionRate;
  const roomsForRequest: Array<any> = [];
  const memberState = store.getState().member;
  const rooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
  rooms.forEach((room: IRoom) => {
    // create room rate request object
    const roomRates = []; //@TODO : consider gift card amount
    if (
      room.rate &&
      room.rate.roomRateNightly &&
      room.rate.roomRateNightly.length > 1
    ) {
      //multi nights
      room.rate.roomRateNightly.forEach((rate: IRoomRate) => {
        const amountBeforeTax = parseFloat(rate.rate);
        roomRates.push({
          amountBeforeTax: amountBeforeTax,
          effectiveDate: rate.effectiveDate,
          expireDate: rate.expireDate,
        });
      });
    } else {
      // add rates for single night.
      const amountBeforeTax = parseFloat(room.rate.subTotal);
      roomRates.push({
        amountBeforeTax: amountBeforeTax,
        effectiveDate: checkout.Start,
        expireDate: checkout.End,
      });
    }

    // create room services object
    const roomServices: Array<any> = [];
    room.services &&
      Object.values(room.services).forEach((ser: IRoomService) => {
        roomServices.push({
          description: ser.Description,
          quantity: ser.AvailedQuantity,
          serviceInventoryCode: ser.ServiceInventoryCode,
          // ServiceRPH: ser.ServiceRPH,
          total:
            (parseFloat(ser.Price) + parseFloat(ser.Tax)) * ser.AvailedQuantity,
        });
      });

    const roomObj = {
      count: {
        adult: room.adults,
        child: {
          numGuests: room.children,
          ages: room.childrenAges
            ? room.childrenAges.map((age: any) => {
                if (age === "") {
                  return 0; // Convert empty string to 0
                } else {
                  return Number(age); // Convert string to number
                }
              })
            : [],
        },
      },
      roomTypeCode: room.room.RoomCode,
      ratePlanCode: room.rate.RateCode,
      roomRates: roomRates,
      roomServices: roomServices,
    };

    roomsForRequest.push(roomObj);

    if (TP_REDEMPTION_RATE.includes(room.rate.RateCode)) {
      isRedemptionRate = true;
    }
  });
  const guestId = checkout?.GuestDetails?.id
    ? checkout?.GuestDetails?.id
    : memberState.isLoggedIn && isRedemptionRate
    ? memberState.crmProfile?.memberId
    : "";
  const requestJSON = {
    hotelId: checkout?.HotelCode ? checkout.HotelCode : "",
    startDate: checkout?.Start ? formatDateGAPI(checkout.Start) : "",
    endDate: checkout?.End ? formatDateGAPI(checkout.End) : "",
    numberOfUnits: 1,
    additionalOptions: "",
    specialRequest: checkout?.SpecialRequests ? checkout.SpecialRequests : "",
    guestList: checkout?.GuestDetails
      ? [
          {
            firstName: checkout?.GuestDetails.firstName,
            lastName: checkout?.GuestDetails.lastName,
            email: checkout?.GuestDetails.email,
            phoneNumber: checkout?.GuestDetails.phoneNumber,
            membershipId: guestId,
            programId: "Travel Pass",
            isPrimary: true,
          },
        ]
      : [],
    roomsList: roomsForRequest,
    emailTemplate: isRedemptionRate ? "TPRedeem" : "Guestconf",
    promotionCode: checkout?.promotionCode ? checkout?.promotionCode : "",
    groupCode: checkout?.groupCode ? checkout?.groupCode : "",
    subSource: "Web",
    guestId: guestId,
    creditCardDetail: ccDetails,
    RedemptionItemCode: checkout?.redemptionItem?.redemptionItemCode || "",
    HotelLocation: checkout?.hotelLocation,
    Brand: checkout?.Brand,
  };

  return requestJSON;
};

export const createReservationPayload = (
  checkout: ICheckout,
  ccDetails: any
): any => {
  let isRedemptionRate;
  const roomsForRequest: Array<any> = [];
  const memberState = store.getState().member;
  const rooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
  const roomIDs = checkout.Rooms ? Object.keys(checkout.Rooms) : [];
  rooms.forEach((room: IRoom, index: number) => {
    // create room services object
    const roomServices: Array<any> = [];
    room.services &&
      Object.values(room.services).forEach((ser: IRoomService) => {
        roomServices.push({
          description: ser.Description,
          quantity: ser.AvailedQuantity,
          serviceInventoryCode: ser.ServiceInventoryCode,
          // ServiceRPH: ser.ServiceRPH,
          total:
            (parseFloat(ser.Price) + parseFloat(ser.Tax)) * ser.AvailedQuantity,
        });
      });

    const roomObj = {
      roomId: roomIDs[index],
      roomServices: roomServices,
    };

    if (TP_REDEMPTION_RATE.includes(room.rate.RateCode)) {
      isRedemptionRate = true;
    }

    roomsForRequest.push(roomObj);
  });

  const guestId = checkout?.GuestDetails?.id
    ? checkout?.GuestDetails?.id
    : memberState.isLoggedIn && isRedemptionRate
    ? memberState.crmProfile?.memberId
    : "";

  const requestJSON = {
    specialRequest: checkout?.SpecialRequests ? checkout.SpecialRequests : "",
    roomsList: roomsForRequest,
    emailTemplate: isRedemptionRate ? "TPRedeem" : "Guestconf",
    guestId: guestId,
    signupAsMember: checkout?.GuestDetails?.helloRewardsSignup
      ? checkout.GuestDetails.helloRewardsSignup
      : false,
    creditCardDetail: ccDetails,
  };

  return requestJSON;
};

export const cancelRedemtionReservation = async (params: any): Promise<any> => {
  const data = {
    memberId: params.memberId,
    isMember: params.isRedemption,
    lastname: params.lastName,
  };
  try {
    const token = await new TokenProvider().getAnonToken();
    const config = await fetchReservationAPIConfig(token);
    const axiosClient = await createAxiosClient({
      apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
    });
    const response = await axiosClient.deleteData(
      `/redemption/reservation/${params.resId}/cancel`,
      { ...config, data: data }
    );
    return response;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};
