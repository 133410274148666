import { IApolloClientProps } from "./ApolloBaseClient";
import { createApolloClient } from "./ApolloClient";
import { TokenProvider } from "../TokenProvider";
import {
  GET_PLACE_DETAILS_RESULTS,
  GET_PLACE_RESULTS,
} from "./SearchServiceQuery";

export class SearchServiceClient {
  public client: any;
  public token: any;

  constructor(params: IApolloClientProps) {
    this.client = createApolloClient(params);
  }
  public async getPlaceDetails(
    placeId: any,
    sessionToken: string,
    placeType: string,
    abortController: any
  ) {
    const result = await this.client.getData(
      {
        query: GET_PLACE_DETAILS_RESULTS,
        variables: {
          input: {
            placeId: placeId,
            sessionToken: sessionToken,
            placeType: placeType,
          },
        },
      },
      this.token,
      false,
      abortController
    );
    return result;
  }

  public async getPlaces(
    searchInput: any,
    sessionToken: string,
    abortController: any
  ) {
    const result = await this.client.getData(
      {
        query: GET_PLACE_RESULTS,
        variables: {
          searchInput: {
            input: searchInput,
            sessionToken: sessionToken,
          },
        },
      },
      this.token,
      false,
      abortController
    );
    return result;
  }
}

export const createSearchServiceClient = async (params: IApolloClientProps) => {
  const client = new SearchServiceClient(params);
  client.token = await new TokenProvider().getAnonToken();
  return client;
};
