import { ApolloBaseClient, IApolloClientProps } from "./ApolloBaseClient";

export class ApolloClient extends ApolloBaseClient {
  public token: any;

  constructor(params: IApolloClientProps) {
    super(params);
  }

  public async getData(
    query: any,
    token: string,
    isMember: boolean,
    abortController: any,
    fetchPolicy: boolean,
    isSynxsAPI: boolean
  ) {
    try {
      const response = await this.client.query({
        query: query.query,
        variables: query.variables,
        ...(fetchPolicy ? { fetchPolicy: "network-only" } : {}),
        context: {
          headers: this.modifyHeaders(token, isMember, isSynxsAPI),
          fetchOptions: abortController
            ? {
                signal: abortController.signal,
              }
            : {},
        },
      });
      return this.processResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  public async postData(mutation: any, token: any, isMember: boolean) {
    try {
      const response: any = await this.client.mutate({
        mutation: mutation.mutation,
        variables: mutation.variables,
        context: {
          headers: this.modifyHeaders(token, isMember, mutation.variables),
        },
      });
      return this.processResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private modifyHeaders(token: any, isMember: boolean, isSynxsAPI: boolean) {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Api-Version": `${
        isMember
          ? process.env.GATSBY_CDP_API_VERSION_FOR_USER_PROFILE_QUERY
          : isSynxsAPI
          ? process.env.GATSBY_GAPI_SYNXIS_API_VERSION
          : process.env.GATSBY_CDP_API_VERSION
      }`,
      // "Api-Version": `${isMember ? "v2.0" : "v1.1"}`,
    };
    return headers;
  }
  private processResponse(response: any) {
    if (response.error) {
      console.error(response.error.message);
      return response.error;
    } else {
      return response?.data;
    }
  }
}
export const createApolloClient = (params: IApolloClientProps) => {
  return new ApolloClient(params);
};
