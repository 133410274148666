import {
  coreGet
} from './core';
import { convertArrayToObject } from '../utils/helpers';

export const fetchHotels = async (distance?: number, latitude?: number | null, longitude?: number | null, siteId = 31, source: any = null): Promise<any> => {
  let hotels = {};
  let apiUrl = `/hotel-proximity?_format=json&site_id=${siteId}`;
  apiUrl += `&coordinates[value]=${distance}`;
  apiUrl += `&coordinates[source_configuration][origin][lat]=${latitude}`;
  apiUrl += `&coordinates[source_configuration][origin][lon]=${longitude}`;
  const data = await coreGet(apiUrl, source);
  if (data !== null) {
    hotels = convertArrayToObject(data, 'crs_code');
  }
  return hotels;
};

export const fetchInterestsHotels = async (distance?: number, latitude?: number | null, longitude?: number | null, siteId = 31, url?: string, source: any = null ): Promise<any> => {
  let hotels = {};
  let apiUrl = `/get_interest_hotels?_format=json&siteId=${siteId}`;
  apiUrl += `&url=${url}`;
  apiUrl += `&lat=${latitude}&lon=${longitude}`;
  apiUrl += `&miles=${distance}`;
  const data = await coreGet(apiUrl, source);
  if (data !== null) {
    hotels = convertArrayToObject(data.data, 'crs_code');
  }
  return hotels;
};
