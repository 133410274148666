import { IApolloClientProps } from "./ApolloBaseClient";
import { createApolloClient } from "./ApolloClient";
import { TokenProvider } from "../TokenProvider";
import { gql } from "@apollo/client";
import {
  GET_SEARCH_RESULTS,
  GET_HOTEL_AVAILABILITY,
  GET_HOTEL_DETAILS,
  GET_HOTEL_PROXIMITY,
  GET_LOCATION_PROXIMITY,
  GUEST_LOGIN_QUERY,
  SEND_RESET_PASSWORD_EMAIL_MUTATION,
  SIGN_UP_MUTATION,
  SIGN_UP_WIFI_MUTATION,
  CHECK_USER_BY_EMAIL_QUERY,
  RESET_PASSWORD_MUTATION,
  GET_HOTEL_CONTACT_QUERY,
  SEARCH_HOTEL_BY_INTEREST,
  CANCEL_RESERVATION_MUTATION,
  GET_RESERVATION_DETAILS_QUERY,
  BRAND_FILTER_QUERY,
  GET_RECENT_SEARCH_QUERY,
  POST_RECENT_SEARCH_QUERY,
} from "./GuestQuery";
import { ICompleteSignUpProcessRequestInput } from "../@types/RequestTypes";
import { ICompleteSignUpResponse } from "../@types/ResponseTypes";

export class GuestClient {
  public client: any;
  public token: any;

  constructor(params: IApolloClientProps) {
    this.client = createApolloClient(params);
  }

  public async guestLogin(username: string, password: string) {
    const result = await this.client.getData(
      {
        query: GUEST_LOGIN_QUERY,
        variables: {
          username: username,
          password: password,
        },
      },
      this.token
    );
    return result;
  }

  public async sendResetPasswordEmail(email: string) {
    const result = await this.client.postData(
      {
        mutation: SEND_RESET_PASSWORD_EMAIL_MUTATION,
        variables: {
          email: email,
        },
      },
      this.token
    );
    return result;
  }

  public async resetPassword(newPassword: string, args: string) {
    const result = await this.client.postData(
      {
        mutation: RESET_PASSWORD_MUTATION,
        variables: {
          newPassword: newPassword,
          args: args,
        },
      },
      this.token
    );
    return result;
  }

  public async completeSignUpProcess(
    requestInput: ICompleteSignUpProcessRequestInput
  ): Promise<ICompleteSignUpResponse> {
    const mutation = gql`
      mutation CompleteSignUpProcess(
        $updateUserProfileRequest: UpdateUserProfileRequestInput!
      ) {
        completeSignUpProcess(
          updateUserProfileRequest: $updateUserProfileRequest
        ) {
          message
          statusCode
          email
        }
      }
    `;
    const result = await this.client.postData(
      {
        mutation,
        variables: { updateUserProfileRequest: requestInput },
      },
      this.token
    );
    return result;
  }

  public async signUp(email: string, firstName: string, lastName: string) {
    const result = await this.client.postData(
      {
        mutation: SIGN_UP_MUTATION,
        variables: {
          email: email,
          firstName: firstName,
          lastName: lastName,
        },
      },
      this.token
    );
    return result;
  }

  public async signUpWifi(
    email: string,
    firstName: string,
    lastName: string,
    hotelName: string
  ) {
    const result = await this.client.postData(
      {
        mutation: SIGN_UP_WIFI_MUTATION,
        variables: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          hotelName: hotelName,
        },
      },
      this.token
    );
    return result;
  }

  public async checkUserByEmail(email: string) {
    const result = await this.client.getData(
      {
        query: CHECK_USER_BY_EMAIL_QUERY,
        variables: {
          email: email,
        },
      },
      this.token
    );
    return result;
  }

  public async getSearchResults(searchString: string, abortController: any) {
    const result = await this.client.getData(
      {
        query: GET_SEARCH_RESULTS,
        variables: {
          input: {
            byText: searchString,
          },
        },
      },
      this.token,
      false,
      abortController
    );
    return result;
  }

  public async getHotelDetails(hotelID: string) {
    const result = await this.client.getData(
      {
        query: GET_HOTEL_DETAILS,
        variables: {
          id: hotelID,
        },
      },
      this.token,
      false,
      null,
      false,
      true
    );
    return result;
  }

  public async getHotelAvailability(
    adults: number,
    children: number,
    childrenAges: Array<string>,
    crsHotelCode: string,
    chainId: string,
    start: string,
    end: string,
    availReqType: string,
    promotionCode: string,
    ratePlanCode: string,
    ratePlanFilterCode: string,
    abortController: any
  ) {
    const formatChildAge = childrenAges
      ? childrenAges.map((age) => {
          if (age === "") {
            return 0; // Convert empty string to 0
          } else {
            return Number(age); // Convert string to number
          }
        })
      : [];
    const result = await this.client.getData(
      {
        query: GET_HOTEL_AVAILABILITY,
        variables: {
          input: {
            adults: adults,
            children: children,
            childrenAges: formatChildAge,
            crsHotelCode: crsHotelCode,
            chainId: chainId,
            quantity: 1,
            start: start,
            end: end,
            availReqType: availReqType,
            promotionCode: promotionCode ? promotionCode.toUpperCase() : "",
            ratePlanCode: ratePlanCode ? ratePlanCode.toUpperCase() : "",
            ratePlanFilterCode: ratePlanFilterCode,
          },
        },
      },
      this.token,
      false,
      abortController,
      true,
      true
    );
    return result;
  }

  public async getHotelProximity(distance: any, latitude: any, longitude: any) {
    const result = await this.client.getData(
      {
        query: GET_HOTEL_PROXIMITY,
        variables: {
          proximity: {
            rangeInMilesFromOrigin: distance,
            coordinates: {
              latitude: latitude,
              longitude: longitude,
            },
          },
        },
      },
      this.token
    );
    return result;
  }

  public async getHotelByInterest(
    distance: any,
    latitude: any,
    longitude: any,
    url: any
  ) {
    const result = await this.client.getData(
      {
        query: SEARCH_HOTEL_BY_INTEREST,
        variables: {
          proximity: {
            rangeInMilesFromOrigin: distance,
            coordinates: {
              latitude: latitude,
              longitude: longitude,
            },
            url: url,
          },
        },
      },
      this.token
    );
    return result;
  }

  public async getHotelContact(hotelCode: string) {
    const result = await this.client.getData(
      {
        query: GET_HOTEL_CONTACT_QUERY,
        variables: {
          input: {
            id: hotelCode,
            idType: "CRS",
          },
        },
      },
      this.token
    );
    return result;
  }

  public async brandFilterHotels(hotelCodes: string) {
    const result = await this.client.getData(
      {
        query: BRAND_FILTER_QUERY,
        variables: {
          hotelCrsCodes: hotelCodes,
        },
      },
      this.token
    );
    return result;
  }

  public async getLocationProximity(
    distance: any,
    latitude: any,
    longitude: any
  ) {
    const result = await this.client.getData(
      {
        query: GET_LOCATION_PROXIMITY,
        variables: {
          input: {
            byProximity: {
              rangeInMilesFromOrigin: distance,
              coordinates: {
                latitude: latitude,
                longitude: longitude,
              },
            },
          },
        },
      },
      this.token
    );
    return result;
  }

  public async cancelReservation(confirmationNumber: string) {
    const result = await this.client.postData(
      {
        mutation: CANCEL_RESERVATION_MUTATION,
        variables: {
          confirmNumber: confirmationNumber,
        },
      },
      this.token,
      false,
      null,
      false,
      true
    );
    return result;
  }

  public async getReservationDetails(
    confirmationNumber: string,
    surname: string
  ) {
    const result = await this.client.getData(
      {
        query: GET_RESERVATION_DETAILS_QUERY,
        variables: {
          confirmationNumber: confirmationNumber,
          surname: surname,
        },
      },
      this.token,
      false,
      null,
      true,
      true
    );
    return result;
  }

  public async getRecentSearch(memberId: string) {
    const result = await this.client.getData(
      {
        query: GET_RECENT_SEARCH_QUERY,
        variables: { memberId: memberId },
      },
      this.token,
      false,
      null,
      true
    );
    return result;
  }

  public async postRecentSearch(
    memberID: string,
    startDate: string,
    hotelID: string,
    endDate: string,
    roomType: string,
    promoCode: string,
    occupancy: string,
    groupCode: string,
    ratePlanFilterCode: string,
    lastUpdated: string
  ) {
    const result = await this.client.postData(
      {
        mutation: POST_RECENT_SEARCH_QUERY,
        variables: {
          memberID: `${memberID}`,
          startDate: startDate,
          hotelID: hotelID,
          endDate: endDate,
          roomType: roomType,
          promoCode: promoCode,
          occupancy: occupancy,
          groupCode: groupCode,
          ratePlanFilterCode: ratePlanFilterCode,
          lastUpdated: lastUpdated,
        },
      },
      this.token,
      false,
      null,
      true
    );
    return result;
  }
}

export const createGuestClient = async (params: IApolloClientProps) => {
  const client = new GuestClient(params);
  client.token = await new TokenProvider().getAnonToken();
  return client;
};
