import { IApolloClientProps } from "./graphql/ApolloBaseClient";
import { createMemberClient, MemberClient } from "./graphql/MemberClient";
import { createGuestClient, GuestClient } from "./graphql/GuestClient";
import { IUpdateMemberProfile } from "../@types/IUpdateMemberProfile";
import {
  ICompleteSignUpProcessRequestInput,
  IUpdateUserProfileRequestInput,
} from "./@types/RequestTypes";
import {
  createSearchServiceClient,
  SearchServiceClient,
} from "./graphql/SearchServiceClient";
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ServiceProvider {
  export class Member {
    public client: MemberClient;
    private static instance: Member;
    public static async getInstance() {
      if (!Member.instance) {
        Member.instance = new Member();
        Member.instance.initializeClient();
      }
      return Member.instance;
    }
    public async getMemberProfile() {
      await this.initializeClient();
      return this.client.getMemberProfile();
    }
    public async editUserProfile(requestInput: IUpdateUserProfileRequestInput) {
      await this.initializeClient();
      return this.client.editUserProfile(requestInput);
    }
    private static async getMemberClient() {
      const params: IApolloClientProps = {
        apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}/member/graphql`,
      };
      const client = await createMemberClient(params);
      return client;
    }
    private initializeClient = async () => {
      if (!this.client) {
        this.client = await Member.getMemberClient();
      }
    };
  }
  export class Guest {
    public client: GuestClient;
    private static instance: Guest;

    public static async getInstance() {
      if (!Guest.instance) {
        Guest.instance = new Guest();
        await Guest.instance.initializeClient();
      }
      return Guest.instance;
    }
    public async guestlogin(username: string, password: string) {
      await this.initializeClient();
      return this.client.guestLogin(username, password);
    }
    public async sendResetPasswordEmail(email: string) {
      await this.initializeClient();
      return this.client.sendResetPasswordEmail(email);
    }
    public async resetPassword(newPassword: string, args: string) {
      await this.initializeClient();
      return this.client.resetPassword(newPassword, args);
    }
    public async checkUserByEmail(email: string) {
      await this.initializeClient();
      return this.client.checkUserByEmail(email);
    }
    public async signUp(email: string, firstName: string, lastName: string) {
      await this.initializeClient();
      return this.client.signUp(email, firstName, lastName);
    }
    public async signUpWifi(
      email: string,
      firstName: string,
      lastName: string,
      hotelName: string
    ) {
      await this.initializeClient();
      return this.client.signUpWifi(email, firstName, lastName, hotelName);
    }
    public async completeSignUpProcess(
      requestInput: ICompleteSignUpProcessRequestInput
    ) {
      await this.initializeClient();
      return this.client.completeSignUpProcess(requestInput);
    }
    public async getHotelDetails(hotelId: string) {
      await this.initializeClient();
      return this.client.getHotelDetails(hotelId);
    }
    public async getSearchResults(searchString: string, abortController: any) {
      await this.initializeClient();
      return this.client.getSearchResults(searchString, abortController);
    }
    public async getHotelAvailability(
      adults: number,
      children: number,
      childrenAges: string,
      crsHotelCode: string,
      chainId: string,
      start: string,
      end: string,
      availReqType: string,
      promotionCode: string,
      ratePlanCode: string,
      ratePlanFilterCode: string,
      abortController: any
    ) {
      await this.initializeClient();
      return this.client.getHotelAvailability(
        adults,
        children,
        childrenAges,
        crsHotelCode,
        chainId,
        start,
        end,
        availReqType,
        promotionCode,
        ratePlanCode,
        ratePlanFilterCode,
        abortController
      );
    }
    public async getHotelByInterest(
      distance: any,
      latitude: any,
      longitude: any,
      url: any
    ) {
      await this.initializeClient();
      return this.client.getHotelByInterest(distance, latitude, longitude, url);
    }
    public async getHotelContact(hotelCode: string) {
      await this.initializeClient();
      return this.client.getHotelContact(hotelCode);
    }
    public async getHotelProximity(
      distance: any,
      latitude: any,
      longitude: any
    ) {
      await this.initializeClient();
      return this.client.getHotelProximity(distance, latitude, longitude);
    }
    public async getLocationProximity(
      distance: any,
      latitude: any,
      longitude: any
    ) {
      await this.initializeClient();
      return this.client.getLocationProximity(distance, latitude, longitude);
    }
    public async brandFilterHotels(hotelCodes: string) {
      await this.initializeClient();
      return this.client.brandFilterHotels(hotelCodes);
    }
    public async cancelReservation(confirmationNumber: string) {
      await this.initializeClient();
      return this.client.cancelReservation(confirmationNumber);
    }
    public async getReservationDetails(
      confirmationNumber: string,
      surname: string
    ) {
      await this.initializeClient();
      return this.client.getReservationDetails(confirmationNumber, surname);
    }
    public async getRecentSearch(memberId: string) {
      await this.initializeClient();
      return this.client.getRecentSearch(memberId);
    }
    public async postRecentSearch(
      memberID: string,
      startDate: string,
      hotelID: string,
      endDate: string,
      roomType: string,
      promoCode: string,
      occupancy: string,
      groupCode: string,
      ratePlanFilterCode: string,
      lastUpdated: string
    ) {
      await this.initializeClient();
      return this.client.postRecentSearch(
        memberID,
        startDate,
        hotelID,
        endDate,
        roomType,
        promoCode,
        occupancy,
        groupCode,
        ratePlanFilterCode,
        lastUpdated
      );
    }
    public static async getGuestClient() {
      const params = {
        apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}/guest/graphql`,
      };
      const client = await createGuestClient(params);
      return client;
    }
    private initializeClient = async () => {
      if (!this.client) {
        this.client = await Guest.getGuestClient();
      }
    };
  }
  export class SearchService {
    public client: SearchServiceClient;
    private static instance: SearchService;

    public static async getInstance() {
      if (!SearchService.instance) {
        SearchService.instance = new SearchService();
        await SearchService.instance.initializeClient();
      }
      return SearchService.instance;
    }
    public async getPlaces(
      searchInput: any,
      sessionToken: string,
      abortController: any
    ) {
      await this.initializeClient();
      return this.client.getPlaces(searchInput, sessionToken, abortController);
    }
    public async getPlaceDetails(
      placeId: any,
      sessionToken: string,
      placeType: string,
      abortController: any
    ) {
      await this.initializeClient();
      return this.client.getPlaceDetails(
        placeId,
        sessionToken,
        placeType,
        abortController
      );
    }

    private static async getSearchServiceClient() {
      const params: IApolloClientProps = {
        apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}/searchservice/graphql`,
      };
      const client = await createSearchServiceClient(params);
      return client;
    }

    private initializeClient = async () => {
      if (!this.client) {
        this.client = await SearchService.getSearchServiceClient();
      }
    };
  }
}

export const memberServiceProvider = async () => {
  const memberInstance = await ServiceProvider.Member.getInstance();
  return memberInstance;
};

export const guestServiceProvider = async () => {
  const guestInstance = await ServiceProvider.Guest.getInstance();
  return guestInstance;
};

export const searchServiceProvider = async () => {
  const searchServiceInstance =
    await ServiceProvider.SearchService.getInstance();
  return searchServiceInstance;
};
